import { compose } from 'ramda';
import { formatDateOnly, formatEndDate } from '../../helpers/util';
import { setSourceAndPrevReportIdFromPrevReportId } from '../../helpers/reportHelper';
import { cleanUpMoneyEntryToFloat } from '../../helpers/currencyHelper';

const convertToNum = (val = '') => +(val || 0);
const convertToInt = (val = '') => +(val || 0 * 100).toFixed(2);
const convertToFloat = (val = '') => +(val || 0 / 100).toFixed(2);
const stringToInt = compose(
  convertToInt,
  convertToNum,
);

export const getNetContributions = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q6aColA) - stringToInt(state.q6bColA),
    );
  }
  return convertToFloat(
    stringToInt(state.q6aColB) - stringToInt(state.q6bColB),
  );
};

export const getNetOperatingExpenditures = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q7aColA) - stringToInt(state.q7bColA),
    );
  }
  return convertToFloat(
    stringToInt(state.q7aColB) - stringToInt(state.q7bColB),
  );
};

export const getTotalIndividualContributions = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q11aiColA) + stringToInt(state.q11aiiColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q11aiColB) + stringToInt(state.q11aiiColB),
  );
};

export const getTotalContributions = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q11aiColA) +
        stringToInt(state.q11aiiColA) +
        stringToInt(state.q11bColA) +
        stringToInt(state.q11cColA) +
        stringToInt(state.q11dColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q11aiColB) +
      stringToInt(state.q11aiiColB) +
      stringToInt(state.q11bColB) +
      stringToInt(state.q11cColB) +
      stringToInt(state.q11dColB),
  );
};

export const getLoanTotals = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q13aColA) + stringToInt(state.q13bColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q13aColB) + stringToInt(state.q13bColB),
  );
};

export const getReceiptTotals = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q11aiColA) +
        stringToInt(state.q11aiiColA) +
        stringToInt(state.q11bColA) +
        stringToInt(state.q11cColA) +
        stringToInt(state.q11dColA) +
        stringToInt(state.q13aColA) +
        stringToInt(state.q13bColA) +
        stringToInt(state.q12ColA) +
        stringToInt(state.q14ColA) +
        stringToInt(state.q15ColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q11aiColB) +
      stringToInt(state.q11aiiColB) +
      stringToInt(state.q11bColB) +
      stringToInt(state.q11cColB) +
      stringToInt(state.q11dColB) +
      stringToInt(state.q13aColB) +
      stringToInt(state.q13bColB) +
      stringToInt(state.q12ColB) +
      stringToInt(state.q14ColB) +
      stringToInt(state.q15ColB),
  );
};

export const getTotalLoanRepayments = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q19aColA) + stringToInt(state.q19bColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q19aColB) + stringToInt(state.q19bColB),
  );
};

export const getTotalRefunds = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q20aColA) +
        stringToInt(state.q20bColA) +
        stringToInt(state.q20cColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q20aColB) +
      stringToInt(state.q20bColB) +
      stringToInt(state.q20cColB),
  );
};

export const getTotalDisbursements = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q17ColA) +
        stringToInt(state.q18ColA) +
        stringToInt(state.q19aColA) +
        stringToInt(state.q19bColA) +
        stringToInt(state.q20aColA) +
        stringToInt(state.q20bColA) +
        stringToInt(state.q20cColA) +
        stringToInt(state.q21ColA),
    );
  }

  return convertToFloat(
    stringToInt(state.q17ColB) +
      stringToInt(state.q18ColB) +
      stringToInt(state.q19aColB) +
      stringToInt(state.q19bColB) +
      stringToInt(state.q20aColB) +
      stringToInt(state.q20bColB) +
      stringToInt(state.q20cColB) +
      stringToInt(state.q21ColB),
  );
};

export const getSubTotals = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q23) +
        stringToInt(state.q11aiColA) +
        stringToInt(state.q11aiiColA) +
        stringToInt(state.q11bColA) +
        stringToInt(state.q11cColA) +
        stringToInt(state.q11dColA) +
        stringToInt(state.q13aColA) +
        stringToInt(state.q13bColA) +
        stringToInt(state.q12ColA) +
        stringToInt(state.q14ColA) +
        stringToInt(state.q15ColA),
    );
  }

  return 0;
};

export const getCashOnHandAtClosing = (col, state) => {
  if (col === 'A') {
    return convertToFloat(
      stringToInt(state.q23) +
        stringToInt(state.q11aiColA) +
        stringToInt(state.q11aiiColA) +
        stringToInt(state.q11bColA) +
        stringToInt(state.q11cColA) +
        stringToInt(state.q11dColA) +
        stringToInt(state.q12ColA) +
        stringToInt(state.q13aColA) +
        stringToInt(state.q13bColA) +
        stringToInt(state.q14ColA) +
        stringToInt(state.q15ColA) -
        stringToInt(state.q17ColA) -
        stringToInt(state.q18ColA) -
        stringToInt(state.q19aColA) -
        stringToInt(state.q19bColA) -
        stringToInt(state.q20aColA) -
        stringToInt(state.q20bColA) -
        stringToInt(state.q20cColA) -
        stringToInt(state.q21ColA),
    );
  }

  return 0;
};

const convertSingle = val => convertToFloat(stringToInt(val));

export const addZerosToF3Z = (f3ZObj) => {
  const keys = Object.keys(f3ZObj);
  const o = {};
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (['committeeName', 'committeeId'].includes(key) || f3ZObj[key]) {
      o[key] = f3ZObj[key];
    } else {
      o[key] = 0.0;
    }
  }
  return o;
};

export const populateZerosForF3Z = (f3Z) => {
  return (f3Z || []).reduce((a, o) => {
    a.push(addZerosToF3Z(o));
    return a;
  }, []);
};

export const createFecReportPayload = (id, currentReport, state) => {
  // We could have the previous report id at this point, in that case we want to pass it in to get the proper balance source, if no previous report id, pass source
  const { adjustedPreviousReportId, previousBalanceSource } = setSourceAndPrevReportIdFromPrevReportId(state.previousReportId || state.previousBalanceSource);
  return {
    ...currentReport.report,
    _id: id,
    reportCode: state.reportCode,
    reportDate: currentReport.report.reportDate
      ? formatDateOnly(currentReport.report.reportDate)
      : '',
    electionDate: formatDateOnly(state.electionDate),
    electionCycle: state.electionCycle || currentReport.report.electionCycle,
    usePostGeneralSummary: state.usePostGeneralSummary || undefined,
    forceColumnBValuesForPreviousAmounts: state.forceColumnBValuesForPreviousAmounts || undefined,
    startDate: formatDateOnly(state.startDate),
    endDate: formatEndDate(state.endDate),
    previousBalanceSource,
    previousReportId: adjustedPreviousReportId,
    textStatement: { text: state.textStatementText },
    isAutomaticConduit: state.isAutomaticConduit,
    f3ZData: populateZerosForF3Z(state.f3ZData),
    fecPacSummaryPreviousBalanceAmounts: {
      ...currentReport.report.fecPacSummaryPreviousBalanceAmounts,
      colB_6a_CashOnHandJan1_19:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_6a_CashOnHandJan1_19),
      colB_Year_ForAbove: state?.fecPacSummaryPreviousBalanceAmounts.colB_Year_ForAbove || '',
      colA_6b_CashOnHandBeginning:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colA_6b_CashOnHandBeginning),
      colB_6c_TotalReceipts:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_6c_TotalReceipts),
      colB_7_TotalDisbursements:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_7_TotalDisbursements),
      colB_8_CashOnHandClose:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_8_CashOnHandClose),
      colB_11ai_Itemized:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_11ai_Itemized),
      colB_11aii_Unitemized:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_11aii_Unitemized),
      colB_11b_PoliticalPartyCommittees:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_11b_PoliticalPartyCommittees),
      colB_11c_OtherPoliticalCommitteesPacs:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_11c_OtherPoliticalCommitteesPacs),
      colB_12_TransfersFromAffiliatedOtherPartyCmtes: cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_12_TransfersFromAffiliatedOtherPartyCmtes),
      colB_13_AllLoansReceived:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_13_AllLoansReceived),
      colB_14_LoanRepaymentsReceived:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_14_LoanRepaymentsReceived),
      colB_15_OffsetsToOperatingExpendituresRefunds: cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_15_OffsetsToOperatingExpendituresRefunds),
      colB_16_RefundsOfFederalContributions:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_16_RefundsOfFederalContributions),
      colB_17_OtherFederalReceiptsDividends: cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_17_OtherFederalReceiptsDividends),
      colB_18a_TransfersFromNonfederalAccountH3:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_18a_TransfersFromNonfederalAccountH3),
      colB_18b_TransfersFromNonfederalLevinH5:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_18b_TransfersFromNonfederalLevinH5),
      colB_21ai_FederalShare:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_21ai_FederalShare),
      colB_21aii_NonfederalShare:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_21aii_NonfederalShare),
      colB_21b_OtherFederalOperatingExpenditures:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_21b_OtherFederalOperatingExpenditures),
      colB_22_TransfersToAffiliatedOtherPartyCmtes:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_22_TransfersToAffiliatedOtherPartyCmtes),
      colB_23_ContributionsToFederalCandidatesCmtes:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_23_ContributionsToFederalCandidatesCmtes),
      colB_24_IndependentExpenditures:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_24_IndependentExpenditures),
      colB_25_CoordinatedExpendMadeByPartyCmtes:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_25_CoordinatedExpendMadeByPartyCmtes),
      colB_26_LoanRepaymentsMade:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_26_LoanRepaymentsMade),
      colB_27_LoansMade:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_27_LoansMade),
      colB_28a_IndividualsPersons:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_28a_IndividualsPersons),
      colB_28b_PoliticalPartyCommittees:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_28b_PoliticalPartyCommittees),
      colB_28c_OtherPoliticalCommittees:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_28c_OtherPoliticalCommittees),
      colB_29_OtherDisbursements:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_29_OtherDisbursements),
      colB_30ai_SharedFederalActivityH6FedShare:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_30ai_SharedFederalActivityH6FedShare),
      colB_30aii_SharedFederalActivityH6Nonfed:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_30aii_SharedFederalActivityH6Nonfed),
      colB_30b_Nonallocable100FedElectionActivity:
        cleanUpMoneyEntryToFloat(state?.fecPacSummaryPreviousBalanceAmounts.colB_30b_Nonallocable100FedElectionActivity),
    },
    fecSummary: {
      ...currentReport.report.fecSummary,
      colA_6a_TotalContributionsNoLoans: convertSingle(state.q6aColA),
      colA_6b_TotalContributionRefunds: convertSingle(state.q6bColA),
      colA_6c_NetContributions: getNetContributions('A', state),
      colA_7a_TotalOperatingExpenditures: convertSingle(state.q7aColA),
      colA_7b_TotalOffsetsToOperatingExpenditures: convertSingle(state.q7bColA),
      colA_7c_NetOperatingExpenditures: getNetOperatingExpenditures('A', state),
      colA_8_CashOnHandAtClose: convertSingle(state.q8ColA),
      colA_9_DebtsTo_TotalsFromSchCAndOrD: convertSingle(state.q9ColA),
      colA_10_DebtsBy_TotalsFromSchCAndOrD: convertSingle(state.q10ColA),
      colA_11ai_IndividualsItemized: convertSingle(state.q11aiColA),
      colA_11aii_IndividualsUnitemized: convertSingle(state.q11aiiColA),
      colA_11aiii_IndividualsTotal: getTotalIndividualContributions('A', state),
      colA_11b_PoliticalPartyCommittees: convertSingle(state.q11bColA),
      colA_11c_OtherPoliticalCommittees: convertSingle(state.q11cColA),
      colA_11d_TheCandidate: convertSingle(state.q11dColA),
      colA_11e_TotalContributions: getTotalContributions('A', state),
      colA_12_TransfersFromOtherAuthorizedCommittees: convertSingle(
        state.q12ColA,
      ),
      colA_13a_LoansMadeOrGuarnByTheCandidate: convertSingle(state.q13aColA),
      colA_13b_AllOtherLoans: convertSingle(state.q13bColA),
      colA_13c_TotalLoans: getLoanTotals('A', state),
      colA_14_OffsetsToOperatingExpenditures: convertSingle(state.q14ColA),
      colA_15_OtherReceipts: convertSingle(state.q15ColA),
      colA_16_TotalReceipts: getReceiptTotals('A', state),
      colA_17_OperatingExpenditures: convertSingle(state.q17ColA),
      colA_18_TransfersToOtherAuthorizedCommittees: convertSingle(
        state.q18ColA,
      ),
      colA_19a_LoanRepaymentByCandidate: convertSingle(state.q19aColA),
      colA_19b_LoanRepayments_AllOtherLoans: convertSingle(state.q19bColA),
      colA_19c_TotalLoanRepayments: getTotalLoanRepayments('A', state),
      colA_20a_Refund_IndividualsOtherThanPolCmtes: convertSingle(
        state.q20aColA,
      ),
      colA_20b_Refund_PoliticalPartyCommittees: convertSingle(state.q20bColA),
      colA_20c_Refund_OtherPoliticalCommittees: convertSingle(state.q20cColA),
      colA_20d_TotalContributionRefunds: getTotalRefunds('A', state),
      colA_21_OtherDisbursements: convertSingle(state.q21ColA),
      colA_22_TotalDisbursements: getTotalDisbursements('A', state),
      colA_23_CashBeginningReportingPeriod: convertSingle(state.q23),
      colA_24_TotalReceiptsThisPeriod: getReceiptTotals('A', state),
      colA_25_Subtotals: getSubTotals('A', state),
      colA_26_TotalDisbursementsThisPeriod: getTotalDisbursements('A', state),
      colA_27_CashOnHandAtClosePeriod: getCashOnHandAtClosing('A', state),

      colB_6a_TotalContributionsNoLoans: convertSingle(state.q6aColB),
      colB_6b_TotalContributionRefunds: convertSingle(state.q6bColB),
      colB_6c_NetContributions: getNetContributions('B', state),
      colB_7a_TotalOperatingExpenditures: convertSingle(state.q7aColB),
      colB_7b_TotalOffsetsToOperatingExpenditures: convertSingle(state.q7bColB),
      colB_7c_NetOperatingExpenditures: getNetOperatingExpenditures('B', state),
      colB_11ai_IndividualsItemized: convertSingle(state.q11aiColB),
      colB_11aii_IndividualsUnitemized: convertSingle(state.q11aiiColB),
      colB_11aiii_IndividualsTotal: getTotalIndividualContributions('B', state),
      colB_11b_PoliticalPartyCommittees: convertSingle(state.q11bColB),
      colB_11c_OtherPoliticalCommittees: convertSingle(state.q11cColB),
      colB_11d_TheCandidate: convertSingle(state.q11dColB),
      colB_11e_TotalContributions: getTotalContributions('B', state),
      colB_12_TransfersFromOtherAuthorizedCommittees: convertSingle(
        state.q12ColB,
      ),
      colB_13a_LoansMadeOrGuarnByTheCandidate: convertSingle(state.q13aColB),
      colB_13b_AllOtherLoans: convertSingle(state.q13bColB),
      colB_13c_TotalLoans: getLoanTotals('B', state),
      colB_14_OffsetsToOperatingExpenditures: convertSingle(state.q14ColB),
      colB_15_OtherReceipts: convertSingle(state.q15ColB),
      colB_16_TotalReceipts: getReceiptTotals('B', state),
      colB_17_OperatingExpenditures: convertSingle(state.q17ColB),
      colB_18_TransfersToOtherAuthorizedCommittees: convertSingle(
        state.q18ColB,
      ),
      colB_19a_LoanRepaymentByCandidate: convertSingle(state.q19aColB),
      colB_19b_LoanRepayments_AllOtherLoans: convertSingle(state.q19bColB),
      colB_19c_TotalLoanRepayments: getTotalLoanRepayments('B', state),
      colB_20a_Refund_IndividualsOtherThanPolCmtes: convertSingle(
        state.q20aColB,
      ),
      colB_20b_Refund_PoliticalPartyCommittees: convertSingle(state.q20bColB),
      colB_20c_Refund_OtherPoliticalCommittees: convertSingle(state.q20cColB),
      colB_20d_TotalContributionRefunds: getTotalRefunds('B', state),
      colB_21_OtherDisbursements: convertSingle(state.q21ColB),
      colB_22_TotalDisbursements: getTotalDisbursements('B', state),
    },
  };
};
